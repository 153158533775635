import React from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { ActionContext } from '../layout/MainContainer';
import { getEndUserCompanyDetail, getViewSettings } from 'state/company/companySelector';
import { getAuthUser, isAuthenticated } from 'state/session/sessionSelector';

import { Box, makeStyles } from '@material-ui/core';
import TranslateMessage from '../common/TranslateMessage/TranslateMessage';
import { CONTENT_GROUP_TYPE, OPTION_LOGIN } from 'configs/AppConfig';
import classNames from 'classnames';
import HomeSort from './components/HomeSort';
import Drawer from '@material-ui/core/Drawer';
import { useMedia } from 'react-use';
import { ExitToApp, LocalLibrary, Person, VpnKey } from '@material-ui/icons';
import { useHistory, useParams } from 'react-router-dom';
import { getIsLoadingHashtag } from 'state/home/homeSelector';

const HomeSidebar = props => {
  const {
    isOpenSidebar,
    setIsOpenSidebar,
    contentGroups,
    onSelectContentGroup,
    sort = {},
    changeSort,
    isDisableSort,
    listSorts = [],
    isAuthen,
    sessionActions: { logout },
    setShowLoginFormModal,
    authUser,
    showSidebar,
    pageSize,
    handleLoadContent = () => {},
    isLoadingHashtag,
    isDisableSortCreatedDate
  } = props;
  const { contentGroupId, contentId } = useParams();

  const style = useSelector((state) => state.search.sidebarSetting) || {};
  const headerStyle = useSelector((state) => state.search.headerSetting) || {};
  const viewSettings = useSelector(getViewSettings) || {};
  const isOpenType = viewSettings.accountType === OPTION_LOGIN.OPEN_TYPE;
  const newInformation = viewSettings.newInformation;

  const { background, active, text, textActive, barBackground, barText } = style;
  const below768 = useMedia('(max-width: 768px)');
  const history = useHistory();

  const useStyle = makeStyles(() => (
    {
      sideBar: {
        minWidth: 200,
        width: below768 ? '66vw' : 200,
        color: text || '#707070',
        fontWeight: 700,
        display: 'flex',
        flexDirection: 'column',
        background: background || '#fff',
        zIndex: 100,
        borderLeft: '1px solid #fff',
        borderRight: '1px solid #fff',
        fontSize: below768 ? '12px' : '16px'
      },
      sideBarmobile: {
        minWidth: 200,
        width: '66vw',
        height: 'calc(100vh - 78px)',
        inset: '78px 0px 0px 0px!important',
        paddingBottom: 40,
        background: background || '#fff',
        color: text || '#707070',
        '& .MuiBackdrop-root': {
          height: 'calc(100vh - 78px)',
          top: '80px'
        }
      },
      groupSort: {
        background: barBackground || '#e2e2e2',
        color: barText || '#707070',
        padding: '0.5rem 1rem',
        width: '100%',
        borderBottom: '1px solid #fff'
      },
      sortOrder: {
        display: 'flex',
        flexFlow: 'row',
        width: '100%',
        cursor: 'pointer',
        height: 'fit-content',
        minHeight: 36,
        '& div': {
          padding: '0.5rem 1rem',
          flex: '1 0 0px',
          display: 'flex',
          flexFlow: 'row wrap',
          wordBreak: 'break-word',
          wordWrap: 'break-word'
        },
        '& .disabled': {
          cursor: 'default',
          color: '#cfccd6'
        },
        '& div:first-child': {
          borderRight: '1px solid #e2e2e2'
        },
        '& div:hover': {
          ['@media (min-width:619px)']: { // eslint-disable-line no-useless-computed-key
            background: active || '#1aa758',
            color: textActive || '#fff'
          }
        },
        '& div.disabled:hover': {
          ['@media (min-width:619px)']: { // eslint-disable-line no-useless-computed-key
            background: 'none',
            color: '#cfccd6'
          }
        }
      },
      sortGenres: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
      },
      sortItem: {
        padding: '0.5rem 1rem',
        cursor: 'pointer',
        wordBreak: 'break-word',
        wordWrap: 'break-word'
      },
      sortItemWrapper: {
        borderBottom: '1px solid #fff',
        '&:hover': {
          ['@media (min-width:619px)']: { // eslint-disable-line no-useless-computed-key
            background: active || '#1aa758',
            color: textActive || '#fff'
          }
        }
      },
      iconOpen: {
        transform: 'rotate(180deg)'
      },
      hideSidebar: {
        transform: 'translateX(-200px)',
        transition: 'transform .3s ease-in-out',
        width: 0,
        minWidth: 'unset',
        position: 'fixed'
      },
      selected: {
        background: active || '#1aa758',
        color: textActive || '#fff'
      },
      disabled: {
        opacity: 0.6,
        pointerEvents: 'none'
      },
      sortSelectbox: {
        paddingRight: '1rem',
        border: '1px solid #fff',
        borderTop: 'none'
      },
      headerMenu: {
        backgroundColor: headerStyle.backgroundColor
      },
      headerItem: {
        color: headerStyle.color || '#707070',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontSize: 10
      },
      sortIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      newInformation: {
        background: barBackground || '#e2e2e2',
        color: barText || '#707070',
        padding: '0.5rem 1rem',
        width: '100%',
        border: '1px solid #fff'
      },
      customCSS: {
        color: text || '#707070',
        fontWeight: 700,
        fontSize: below768 ? '12px' : '16px'
      },
      textInfo: {
        '& p:has(em)': {
          transform: 'skewX(-8deg)'
        },
        '& span:has(em)': {
          transform: 'skewX(-8deg)'
        },
        '& em': {
          color: text || '#707070'
        }
      }
    }
  ));
  const classes = useStyle();

  const specialContentGroup = contentGroups.filter(item => item.type !== CONTENT_GROUP_TYPE.NORMAL);
  const normalContentGroup = contentGroups.filter(item => item.type === CONTENT_GROUP_TYPE.NORMAL);

  const onSort = (sort, asc) => {
    const params = { sortBy: sort, sort: asc };
    changeSort(params);
  };

  const renderInformation = () => {
    let newFormat;
    // eslint-disable-next-line no-unused-vars
    if (newInformation.includes('<ul>')) {
      newFormat = newInformation.replaceAll('&nbsp;', '').replaceAll('<li></li>', '');

      if (newFormat.includes('</li>')) {
        return (
          <React.Fragment>
            <div className={`${classes.newInformation} ${classes.customCSS}`}>
              <TranslateMessage id="title.newInformation" />
            </div>
            <div className={classes.textInfo} style={{ padding: '5px 5px 5px 0px' }}>
              <div className={classes.textInfo} style={{ wordBreak: 'break-word' }} dangerouslySetInnerHTML={{ __html: newFormat }} />
            </div>
          </React.Fragment>
        );
      } else {
        return (
          <></>
        );
      }
    } else {
      // if (newInformation.includes('<p></p>')) {
      //   newFormat = newInformation.trim().replaceAll('&nbsp;', '').replaceAll('<p></p>', '').split('\n');
      // } else {
      newFormat = newInformation.trim().replaceAll('&nbsp;', '').replaceAll('<p></p>', '').split('\n');
      // }
      const isNewFormat = newFormat.filter(item => item !== '');
      if (isNewFormat.length === 0) {
        return (
          <></>
        );
      } else {
        return (
          <React.Fragment>
            <div className={`${classes.newInformation} ${classes.customCSS}`}>
              <TranslateMessage id="title.newInformation" />
            </div>
            <div style={{ padding: '5px 5px 5px 0px' }}>
              <ul>
                {newFormat.map((item, index) => (
                  item && item !== '' && <li key={index} className={classes.textInfo}>
                    <div className={classes.textInfo} style={{ wordBreak: 'break-word' }} dangerouslySetInnerHTML={{ __html: item }} />
                  </li>
                ))}
              </ul>
            </div>
          </React.Fragment>
        );
      }
    }
  };

  const sidebarContent = () => (
    <React.Fragment>
      {
        listSorts.length > 0 && <React.Fragment>
          <div className={classes.groupSort}>
            <TranslateMessage id="title.sortOrder" />
          </div>
          {
            listSorts.length > 2
              ? <div className={`${classes.sortSelectbox} ${isDisableSort && classes.disabled}`}>
                <HomeSort
                  listSortField={listSorts}
                  valueSort={sort.sortBy}
                  isAsc={sort.sort}
                  onSort={onSort}
                  disabled={isDisableSort}
                  isDisableSortCreatedDate={isDisableSortCreatedDate}
                />
              </div>
              : <div className={`${classes.sortOrder} ${isDisableSort && classes.disabled}`}>
                {
                  listSorts.map((sortItem, index) => {
                    const isDisabled = (isDisableSortCreatedDate && sortItem.field === 12);
                    return (
                      <div key={`sort-item-${index}`} onClick={() => !isDisabled && onSort(sortItem.field, sort.sortBy === sortItem.field ? !sort.sort : sortItem.isAcsDefault)} className={`${sort.sortBy === sortItem.field && classes.selected} ${isDisabled ? 'disabled' : ''}`}>
                        <span>{sortItem.title}</span>
                        {
                          sort.sortBy === sortItem.field && sort.sort === false && (
                            <i className={classNames(['fas fa-sort-down ml-1', classes.sortIcon])}></i>
                          )
                        }
                        {
                          sort.sortBy === sortItem.field && sort.sort === true && (
                            <i className={classNames(['fas fa-sort-up ml-1', classes.sortIcon])}></i>
                          )
                        }
                      </div>
                    );
                  })
                }
              </div>
          }
        </React.Fragment>
      }
      {
        specialContentGroup && (
          specialContentGroup.map(item => (
            <div
              key={`special-group-${item.id}`}
              className={classNames(
                {
                  [classes.groupSort]: true,
                  [classes.sortItem]: true,
                  [classes.selected]: `${contentGroupId}` === `${item.id}` && !contentId
                }
              )}
              onClick={() => {
                onSelectContentGroup(item.id, `${contentGroupId}` === `${item.id}` && !contentId);
                const pageNum = item.page ? item.page : 1;
                const numItems = contentGroupId
                  ? pageSize
                  : item.isWrapLine ? item.itemSize : item.numRow * item.itemSize;
                handleLoadContent(item, pageNum, numItems);
              }}
            >
              {item.name}
            </div>
          ))
        )
      }
      <div className={classes.groupSort}>
        <TranslateMessage id="title.differentGenres" />
      </div>
      <div className={classes.sortGenres}>
        {
          normalContentGroup.map(item => {
            return (
              <div
                key={`cg-${item.id}`}
                className={`${classes.sortItemWrapper} ${`${contentGroupId}` === `${item.id}` && !contentId && classes.selected}`}
                onClick={() => !isLoadingHashtag && onSelectContentGroup(item.id, `${contentGroupId}` === `${item.id}` && !contentId)}
                style={{
                  pointerEvents: isLoadingHashtag ? 'none' : ''
                }}
              >
                <div className={classes.sortItem}>
                  {item.name}
                </div>
              </div>
            );
          })
        }
      </div>
      {newInformation && renderInformation()}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {
        below768
          ? <Drawer
            anchor="left"
            open={isOpenSidebar}
            classes={{
              root: classNames(classes.sideBarmobile),
              paper: classNames(classes.sideBarmobile)
            }}
            onClose={() => setIsOpenSidebar(false)}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            <Box display="flex" padding={'16px 6px'} justifyContent="space-around" className={classes.headerMenu}>
              <Box className={classes.headerItem}>
                <LocalLibrary onClick={() => history.push('/home-search')} />
                <TranslateMessage id="menu.books" />
              </Box>
              {
                isOpenType
                  ? null
                  : isAuthen ? <React.Fragment>
                    {
                      authUser.roleLevel !== 4 && <Box className={classes.headerItem}>
                        <Person className="mr-2" onClick={() => history.push('/profile')} />
                        <TranslateMessage id="label.profile" />
                      </Box>
                    }
                    <Box className={classes.headerItem}>
                      <ExitToApp onClick={logout} />
                      <TranslateMessage id="label.logout" />
                    </Box>
                  </React.Fragment>
                    : <Box className={classes.headerItem}>
                      <VpnKey onClick={() => {
                        setShowLoginFormModal(true);
                        setIsOpenSidebar(false);
                      }} />
                      <TranslateMessage id="menu.login" />
                    </Box>
              }
            </Box>
            {
              showSidebar
                ? <div className={`home-sidebar ${classes.sideBar}`}>
                  {sidebarContent()}
                </div >
                : null
            }
          </Drawer>
          : showSidebar
            ? <div className={`home-sidebar ${classes.sideBar} ${!isOpenSidebar && classes.hideSidebar}`}>
              {sidebarContent()}
            </div >
            : null
      }
    </React.Fragment>
  );
};

HomeSidebar.propTypes = {
  messageActions: PropTypes.object.isRequired,
  companyActions: PropTypes.object.isRequired,
  companyDetail: PropTypes.object,
  isOpenSidebar: PropTypes.bool,
  isDisableSort: PropTypes.bool,
  contentGroups: PropTypes.array,
  onSelectContentGroup: PropTypes.func,
  sort: PropTypes.object,
  changeSort: PropTypes.func,
  listSorts: PropTypes.any,
  setIsOpenSidebar: PropTypes.func,
  isAuthen: PropTypes.bool,
  sessionActions: PropTypes.object.isRequired,
  authUser: PropTypes.object,
  showSidebar: PropTypes.bool,
  pageSize: PropTypes.any,
  handleLoadContent: PropTypes.func,
  setShowLoginFormModal: PropTypes.func,
  isLoadingHashtag: PropTypes.bool,
  isDisableSortCreatedDate: PropTypes.bool

};

const mapStateToProps = (state) => {
  return {
    companyDetail: getEndUserCompanyDetail(state),
    isAuthen: isAuthenticated(state),
    authUser: getAuthUser(state),
    isLoadingHashtag: getIsLoadingHashtag(state)
  };
};

export default connect(mapStateToProps)(
  props => (
    <ActionContext.Consumer>
      {({ messageActions, companyActions, sessionActions }) => (
        <HomeSidebar
          {...props}
          messageActions={messageActions}
          companyActions={companyActions}
          sessionActions={sessionActions}
        />
      )}
    </ActionContext.Consumer>
  )
);
